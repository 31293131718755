import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import LogoRosa from "../images/logo-rosa.svg"
import tecnologiasCss from "../css/tecnologias.module.css"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      word: "ADAPTABLE",
    }
  }
  render() {
    return (
      <Layout siteTitle="Home">
        <Link to="/" className={tecnologiasCss.logoRosa}>
          <img src={LogoRosa} alt="Logotipo de engrane version rosa" />
        </Link>
        <SEO title="Tecnológias" />
        <div className={tecnologiasCss.container}>
          <div className={tecnologiasCss.sliderLeft}>
            <a href="https://facebook.com" className={tecnologiasCss.tech}>
              JavaScript
            </a>
            <a href="https://facebook.com" className={tecnologiasCss.tech}>
              HTML y CSS
            </a>
            <a href="https://facebook.com" className={tecnologiasCss.tech}>
              Node.js
            </a>
            <a href="https://facebook.com" className={tecnologiasCss.tech}>
              Angular, React y Vue.js
            </a>
            <a href="https://facebook.com" className={tecnologiasCss.tech}>
              ASP.NET MVC V5{" "}
            </a>
            <a href="https://facebook.com" className={tecnologiasCss.tech}>
              Android
            </a>
            <a href="https://facebook.com" className={tecnologiasCss.tech}>
              React Native
            </a>
            <a href="https://facebook.com" className={tecnologiasCss.tech}>
              Cordova{" "}
            </a>
          </div>
          <div className={tecnologiasCss.sliderRight}>
            <a href="https://facebook.com" className={tecnologiasCss.tech}>
              Spring
            </a>
            <a href="https://facebook.com" className={tecnologiasCss.tech}>
              Micronaut
            </a>
            <a href="https://facebook.com" className={tecnologiasCss.tech}>
              Grails
            </a>
            <a href="https://facebook.com" className={tecnologiasCss.tech}>
              Herramientas Build
            </a>
            <a href="https://facebook.com" className={tecnologiasCss.tech}>
              Control de versiones
            </a>
            <a href="https://facebook.com" className={tecnologiasCss.tech}>
              Cliente HTTP
            </a>
            <a href="https://facebook.com" className={tecnologiasCss.tech}>
              Herramientas para bases de datos{" "}
            </a>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
